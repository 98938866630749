import { styled } from 'twin.macro';
import React from 'react';
import { Link } from 'gatsby';
import { Subtitle, Heading, Paragraph } from '../atoms';

const Card = styled.div`
  :hover {
    h6,
    p {
      color: #fff !important;
    }
    .divider {
      background: #fff !important;
    }
  }
`;

const services = [
  {
    id: 1,
    icon: 'icons/app-development.png',
    title: 'Mobile App Development',
    subtitle:
      'Get Top-notch, Extensively, Innovative Mobile App Solution By Us.',
  },
  {
    id: 2,
    icon: '/icons/web-development.png',
    title: 'Web Development',
    subtitle:
      'Custom web application development gives you a greater control over code and implementation.',
  },
  {
    id: 3,
    icon: '/icons/ecommerce.png',
    title: 'E-Commerce Development',
    subtitle: 'Outsource your product development from ideation to delivery.',
  },
  {
    id: 4,
    icon: '/icons/software-development.png',
    title: 'Software Development',
    subtitle: 'We ease complex business need through our Software solutions',
  },
  {
    id: 5,
    icon: '/icons/testing.png',
    title: 'Testing & QA Services',
    subtitle:
      'Our experts test all solutions and ensuring brilliant performances',
  },
  {
    id: 6,
    icon: '/icons/warehouse.png',
    title: 'Internet of Things',
    subtitle: 'Our experience tailor mindful IoT solutions for your needs',
  },
];
const Services = () => (
  <div id="services" tw="mx-auto mt-20 container px-4 text-center">
    <div tw="mx-auto max-w-screen-md">
      <Heading>Development Services</Heading>
      <Paragraph>
        Be it full-service web application, mobile app or a prototype to start
        with, or on the other side AI/BI tools and CRM integration, we do it
        all!
      </Paragraph>
    </div>
    <div tw="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:gap-10 gap-6 mt-20">
      {services.map((item) => (
        <Card
          key={item.id}
          tw="shadow-3xl bg-white p-7 hover:bg-tertiary transition transform hover:-translate-y-4 motion-reduce:transition-none motion-reduce:transform-none"
          bgColor={item.bgColor}
        >
          <img src={item.icon} alt="icon" width={55} tw="mx-auto mb-6" />
          <Subtitle>{item.title}</Subtitle>
          <Paragraph>{item.subtitle}</Paragraph>
          <Link to="/">
            <spam tw="text-white underline">Read More</spam>
          </Link>
        </Card>
      ))}
    </div>
  </div>
);
export default Services;
