/* eslint-disable jsx-a11y/media-has-caption */
import { styled } from 'twin.macro';
import React from 'react';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Hero from '../components/service/Hero';
import config from '../utils/config';
import Header from '../components/Header';
import Services from '../components/service/Services';
import Testimonial from '../components/service/Testimonial';
import Qualities from '../components/service/Qualities';
import WorkIndustries from '../components/service/WorkIndustries';
// import Blogs from '../components/Blogs';

const Container = styled.div``;

const ServicesPage = () => (
  <Layout hideHeader>
    <Seo
      title="Services"
      description={config.description}
      url={config.siteUrl}
      image={config.logo}
    />
    <Container>
      <Header />
      <Hero />
      <Services />
      <Qualities />
      <WorkIndustries />
      <Testimonial />
    </Container>
  </Layout>
);
export default ServicesPage;
