import React from 'react';
import 'twin.macro';
import { Subtitle, Heading, Paragraph } from '../atoms';

const workIndustries = [
  { id: 1, icon: '/icons/ecommerce-work.png', title: 'E-commerce, Retail' },
  { id: 2, icon: '/icons/education.png', title: 'Education & e-learning' },
  { id: 3, icon: '/icons/healthcare.png', title: 'Healthcare & Wellness' },
  {
    id: 4,
    icon: '/icons/distributed.png',
    title: 'Coordinations & Dispersion',
  },
  { id: 5, icon: '/icons/social-media.png', title: 'Social Networking' },
  { id: 6, icon: '/icons/real-estate.png', title: 'Real Estate' },
  { id: 7, icon: '/icons/location.png', title: 'Travel & Hospitality' },
  { id: 8, icon: '/icons/restaurant.png', title: 'Food & Restaurant' },
];
const WorkIndustries = () => (
  <div id="works" tw="mx-auto mt-32 container px-4 text-center">
    <Heading>Industries we serve</Heading>
    <Paragraph>
      Here, we make nearly each class of applications. You title it and we
      construct it.
    </Paragraph>
    <div tw="grid md:grid-cols-4 grid-cols-1 md:gap-8 gap-6 mt-24 max-w-screen-lg mx-auto">
      {workIndustries.map((item) => (
        <div
          key={item.id}
          tw="text-center shadow-3xl py-10 px-3 rounded-xl bg-white"
        >
          <img src={item.icon} alt="works" tw="mx-auto h-14 w-14  mb-6" />

          <Subtitle fontNormal>{item.title}</Subtitle>
        </div>
      ))}
    </div>
  </div>
);
export default WorkIndustries;
