import 'twin.macro';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Heading, Paragraph, Button } from '../atoms';

const Hero = () => (
  <div tw="container mx-auto px-4 mt-12">
    <div tw="flex items-center flex-wrap justify-between">
      <div tw="md:w-6/12 w-full">
        <Heading isLarge>IT Solutions Company</Heading>
        <Heading>
          Customized web & mobile application development solutions.
        </Heading>
        <div tw="mt-8 mb-10 lg:mr-16">
          <Paragraph>
            We have helped customers across industries & geographies to extend
            their business to the mobile channel and differentiate their brand
            with human-centric digital experiences.
          </Paragraph>
        </div>
        <Link to="/request-quote">
          <Button>Get Quote Now</Button>
        </Link>
      </div>
      <div tw="md:w-5/12 w-full">
        <StaticImage
          src="../../../static/images/hero.webp"
          alt="startso"
          placeholder="blurred"
        />
      </div>
    </div>
  </div>
);
export default Hero;
