import { styled } from 'twin.macro';
import React from 'react';
import { Subtitle, Heading, Paragraph } from '../atoms';

const Container = styled.div`
  background: rgba(0, 0, 0, 0) url('/images/bg-pattern.png');
`;

const services = [
  {
    id: 1,
    icon: 'icons/reliable-solution.png',
    title: 'Reliable Solutions',
    subtitle:
      'Give cleverly arrangements breaking any frame of complexity through our broad encounter.',
  },
  {
    id: 2,
    icon: '/icons/interactive-design.png',
    title: 'Intuitively Plans',
    subtitle:
      'Arrange and plan instinctively to form interfacing more responsive for the clients',
  },
  {
    id: 3,
    icon: '/icons/good-communication.png',
    title: 'Impressive Communication',
    subtitle:
      'Well organized to keep always in touch with our clients when they require us.',
  },
  {
    id: 4,
    icon: '/icons/fast-delivery.png',
    title: 'Prompt Delivery',
    subtitle:
      'Arrange, develop and provide ventures expeditiously as how it is guaranteed at the starting.',
  },
  {
    id: 5,
    icon: '/icons/cost-effective-solution.png',
    title: 'Cost-effective Arrangements',
    subtitle:
      'Solutions that are pocket friendly and add more value to your business with better ROI.',
  },
  {
    id: 6,
    icon: '/icons/quality-assurance.png',
    title: 'Quality of Service',
    subtitle:
      'Guarantee client fulfillment through ceaselessly conveying amazing administrations faultlessly.',
  },
];
const Qualities = () => (
  <Container tw="mt-32 mb-24 pt-24 pb-32">
    <div tw="mx-auto container px-4">
      <div tw="mx-auto max-w-screen-lg text-center">
        <Heading>Creative development craftsmanship</Heading>
        <Paragraph>
          Our refined, definite, and technically well molded works that let your
          users speechless
        </Paragraph>
      </div>
      <div tw="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:gap-8 gap-6 mt-20">
        {services.map((item) => (
          <div
            key={item.id}
            tw="flex p-5 space-x-4 bg-white hover:shadow-3xl max-w-md rounded-sm border transition transform hover:-translate-y-2 motion-reduce:transition-none motion-reduce:transform-none"
          >
            <img src={item.icon} alt="icon" tw="h-14 w-14" />
            <div>
              <Subtitle>{item.title}</Subtitle>
              <Paragraph>{item.subtitle}</Paragraph>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Container>
);
export default Qualities;
